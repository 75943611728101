export default [{
    header: 'Settings',
  },
  {
    title: 'Application',
    icon: 'UsersIcon',
    route: 'settings-application-country',
  },

  {
    title: 'Metadata',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Vaccination',
        route: 'metadata-general-assistance-vaccination',
      },
      {
        title: 'Vaccination Dosage',
        route: 'metadata-general-assistance-vaccinationdosage',
      },
      // {
      //   title: 'Householde Equipments',
      //   route: 'metadata-assets-householdequipments',
      // },
    ],
  },

  {
    title: 'Physical Assets',
    icon: 'ZapIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Automobile',
        route: 'metadata-physicalassets-automobile',
      },
      {
        title: 'Household Sevrice',
        route: 'metadata-physicalassets-householdservices',
      },
      {
        title: 'Householde Equipments',
        route: 'metadata-physicalassets-householdequipments',
      },
      {
        title: 'Arts and Collectible',
        route: 'metadata-physicalassets-artsandcollectible',
      },
      {
        title: 'Important contacts',
        route: 'metadata-physicalassets-importantcontacts',
      },
    ],
  },

  {
    title: 'Financial Assets',
    icon: 'ZapIcon',
    tagVariant: 'light-warning',
    children: [
      // {
      //   title: 'Bank',
      //   route: 'metadata-finance-bank',
      // },
      // {
      //   title: 'Mutual Fund',
      //   route: 'metadata-finance-mutualfund',
      // },
      // {
      //   title: 'Loan',
      //   route: 'metadata-finance-loan',
      // },
      {
        title: 'Broker',
        route: 'metadata-financialassets-broker',
      },

      {
        title: 'Wallet',
        route: 'metadata-financialassets-wallet',
      },
      {
        title: 'Paylater Wallets',
        route: 'metadata-financialassets-paylaterwallets',
      },
    ],
  },

  {
    title: 'Utilities',
    icon: 'TvIcon',
    tagVariant: 'light-warning',
    children: [{
        title: 'Electricity',
        route: 'metadata-general-utilities-electricity',
      },
      {
        title: 'Gas',
        route: 'metadata-general-utilities-gas',
      },
      {
        title: 'Water',
        route: 'metadata-general-utilities-water',
      },

    ],
  },

  {
    title: 'Subscriptions',
    icon: 'RssIcon',
    tagVariant: 'light-warning',
    children: [
      // {
      //   title: 'Income',
      //   route: 'metadata-transaction-income',
      // },
      {
        title: 'Subscriptions',
        route: 'metadata-subscriptions-subscriptions',
      },
      {
        title: 'Subscriptions Sub',
        route: 'metadata-subscriptionssub-subscriptionssub',
      },
    ],
  },
  // {
  //   title: 'Subscriptions',
  //   route: 'metadata-assets-subscriptions',
  // },
  {
    title: 'Transaction',
    icon: 'TrendingDownIcon',
    tagVariant: 'light-warning',
    children: [
      // {
      //   title: 'Income',
      //   route: 'metadata-transaction-income',
      // },
      {
        title: 'Expense',
        route: 'metadata-transaction-expense',
      },
      {
        title: 'Expense Sub',
        route: 'metadata-transaction-expensesub',
      },
    ],
  },
  {
    title: 'Communications',
    icon: 'PhoneIcon',
    tagVariant: 'light-warning',
    children: [{
        title: 'Cable Tv',
        route: 'metadata-general-communication-cabletv',
      },
      {
        title: 'Landbroad',
        route: 'metadata-general-communication-landbroad',
      },
      {
        title: 'Mobile',
        route: 'metadata-general-communication-mobile',
      },

    ],
  },


  {
    title: 'Insurance',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [{
        title: 'Life',
        route: 'metadata-insurance-life',
      },
      {
        title: 'General',
        route: 'metadata-insurance-general',
      },
    ],
  },
  {
    title: 'Import Export',
    icon: 'ShieldIcon',
    route: 'import-export',
  },

]
