<template>
  <div>
    <div class="">
      <b-button
        v-for="item,index in list"
        :key="'list'+index"
        :to="{ name: item.path }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="data[2] == item.name ? 'primary' : 'flat-primary'"
      >
        {{item.title}}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.$watch(
      "$props",
      () => {
        this.data = this.data;
      },
      { deep: true }
    );
  },

  data(){
    return {
      data:this.$route.name.split('-'),
      list:[
        {
          title:'Pricing',
          path:'settings-application-pricing',
          name:'pricing',
        },
        {
          title:'Storage',
          path:'settings-application-storage',
          name:'storage',
        },
        {
          title:'Country',
          path:'settings-application-country',
          name:'country',
        },
        {
          title:'State',
          path:'settings-application-state',
          name:'state',
        },
        {
          title:'CMS',
          path:'settings-application-cms',
          name:'cms',
        },
        {
          title:'Relations',
          path:'settings-application-relations',
          name:'relations',
        },
        // {
        //   title:'Vaccination',
        //   path:'settings-application-vaccination',
        //   name:'vaccination',
        // },
      ]
    };
  }
};
</script>
