<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content v-if="appData">
      <feather-icon
        :badge="appData.length>0?appData.length:null"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary" v-if="appData"> {{appData.length}} Messages </b-badge>
      </div>
    </li>
    <li class="dropdown-menu-footer">
      <b-link
      :to="{
            name: 'settings-alerts',
          }"
      >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        >Read all notifications</b-button
      >
      </b-link>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import { ref, onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,

    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "setting-alerts";
    const modulename = "Alerts";
    const modulenamesub = "alerts";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    const appData = ref([]);

    // store
    //   .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/getalerts", {})
    //   .then((response) => {
    //     appData.value = response.data["data"];
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     if (error.response.status === 404) {
    //       appData.value = undefined;
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error",
    //           text: "Couldnt find " + modulenamesub + " details",
    //           icon: "AlertTriangleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     } else if (error.response.status === 500) {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error",
    //           text: "Input fields missing values",
    //           icon: "AlertTriangleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     } else if (error.response.status === 422) {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error",
    //           text: "Please refresh your page",
    //           icon: "AlertTriangleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     }
    //   });
    const notifications = [
      // {
      //   title: "Alerts ",
      //   // avatar: reqsuire('@/assets/images/avatars/6-small.png'),
      //   // subtitle: 'Won the monthly best seller badge',
      //   router: "setting-alerts",
      //   type: "light-success",
      // },
      {
        title: "Congratulation Sam 🎉",
        avatar: require("@/assets/images/avatars/6-small.png"),
        subtitle: "Won the monthly best seller badge",
        type: "light-success",
      },
      {
        title: "New message received",
        avatar: require("@/assets/images/avatars/9-small.png"),
        subtitle: "You have 10 unread messages",
        type: "light-info",
      },
      {
        title: "Revised Order 👋",
        avatar: "MD",
        subtitle: "MD Inc. order updated",
        type: "light-danger",
      },
    ];
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: "Server down",
        subtitle: "USA Server is down due to hight CPU usage",
        type: "light-danger",
        icon: "XIcon",
      },
      {
        title: "Sales report generated",
        subtitle: "Last month sales report generated",
        type: "light-success",
        icon: "CheckIcon",
      },
      {
        title: "High memory usage",
        subtitle: "BLR Server using high memory",
        type: "light-warning",
        icon: "AlertTriangleIcon",
      },
    ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
      appData,
    };
  },
};
</script>

<style>
</style>
